import React, { useState } from "react";
import HeaderComponent from "../headerComponent";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const ApplyHomeComponent = (props) => {
  const {
    contactHomeTitle,
    contactHomeDescription,
    firstName,
    lastName,
    email,
    message,
    formButton,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const onSubmitHandler = (values) => {
    setIsLoading(true);
    const { firstName, lastName, email, message } = values;
    console.log(values);
    debugger;
    const postUrl = `https://api-mail.motomtech.com/send-email`;
    axios
      .post(postUrl, {
        firstName,
        lastName,
        email,
        message,
        subject: "Contact Us",
      })
      .then(() => {
        setIsLoading(false);
        setIsSuccess(true);
        toast.success("Message sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reset();
      })
      .catch((err) => {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        toast.error("There was a problem. Please check again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <div className="contact-home-section">
       <HeaderComponent />
      <div className="full-container">
        <div className="contact-home-section-wrapper">
          <div className="row">
            <div className="col-md-6 contact-home-section-col">
              <div className="contact-home-section-text">
                <h1 className="contact-home-section-title">
                  {contactHomeTitle}
                </h1>
                <p className="contact-home-section-description">
                  {contactHomeDescription}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-home-section-form">
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="contact-home-section-form-label">
                        {firstName}
                      </label>
                      <div className="contact-home-section-form-input-section">
                        <input
                          type="text"
                          className={`contact-home-section-form-input ${
                            errors.firstName ? "is-invalid" : ""
                          }`}
                          placeholder="First name"
                          id="firstName"
                          name="firstName"
                          {...register("firstName", {
                            required: "Enter your first name!",
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.firstName?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="contact-home-section-form-label">
                        {lastName}
                      </label>
                      <div className="contact-home-section-form-input-section">
                        <input
                          type="text"
                          className={`contact-home-section-form-input ${
                            errors.lastName ? "is-invalid" : ""
                          }`}
                          placeholder="Last name"
                          id="lastName"
                          name="lastName"
                          {...register("lastName", {
                            required: "Enter your last name!",
                          })}
                        />
                        <div className="invalid-feedback">
                          {errors.lastName?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="contact-home-section-form-label">
                      {email}
                    </label>
                    <div className="contact-home-section-form-input-section">
                      <input
                        type="email"
                        className={`contact-home-section-form-input ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="you@company.com"
                        id="email"
                        name="email"
                        {...register("email", {
                          required: "Enter your email!",
                        })}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <label className="contact-home-section-form-label">
                    {message}
                  </label>
                  <div className="contact-home-section-form-input-section">
                    <textarea
                      rows={4}
                      type="text"
                      className={`contact-home-section-form-input `}
                      id="message"
                      name="message"
                      {...register("message")}
                    />
                  </div>
                  <button
                    type="submit"
                    className="contact-home-section-form-button"
                  >
                    {!isLoading ? (
                      <>{formButton}</>
                    ) : (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </form>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyHomeComponent;
