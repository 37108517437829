import React from 'react';
import ApplyHomeComponent from '../component/Apply/applyHomeComponent';
import GetComponent from '../component/Apply/getComponent';
import FooterComponent from '../component/footerComponent';
import Layout from '../component/layout';
import { graphql } from 'gatsby';
const Contact = props => {
  const {
    data: {
      wpPage: { seo },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <ApplyHomeComponent
        contactHomeTitle='Reach out to our team'
        contactHomeDescription='Grow your business with our expertise and Agile methodologies. We will build technology solutions that allow you to focus on your core business instead of mitigating risks, overseeing the quality, or coordinating teams. '
        firstName='First name'
        lastName='Last name'
        email='Email'
        message='Message'
        formButton='Send message'
        checboxText='You agree to our friendly '
        privacyText='privacy policy.'
      />
      <GetComponent
        getTitle='Get in touch'
        getDescription='Our team is always here '
        getEmailTitle='Email'
        getEmailDescription='Our friendly team is here to help.'
        getEmailLink='imelda@uptechshpk.com'
        getLocation='Office'
        getLocationDescription='Come say hello at our office HQ.'
        getLocationLink='Kompleksi Kika 2, Tirana'
        getPhone='Phone'
        getPhoneDescription='Mon-Fri from 8am to 5pm.'
        getPhoneLink='(+355) 67-544-6541'
        getLocationLinkLocation='Kompleksi Kika 2 Tirane, Albania '
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "contact-motomtech" }) {
      seo {
        metaDesc
        title
      }
    }
  }
`;
export default Contact;
