import React from 'react';
import GetEmail from '../../../static/assets/getEmail.svg';
import GetLocation from '../../../static/assets/getLocation.svg';
import GetPhone from '../../../static/assets/getPhone.svg';
const GetComponent = props => {
  const {
    getTitle,
    getDescription,
    getEmailTitle,
    getEmailDescription,
    getEmailLink,
    getLocation,
    getLocationDescription,
    getLocationLink,
    getPhone,
    getPhoneDescription,
    getPhoneLink,
    getLocationLinkLocation,
  } = props;

  return (
    <div className='get-section'>
      <div className='full-container'>
        <h2 className='get-section-title'>{getTitle}</h2>
        <p className='get-section-description'>{getDescription}</p>
        <div className='row'>
          <div className='col-md-4'>
            <GetEmail />
            <p className='get-section-contact-title'>{getEmailTitle}</p>
            <p className='get-section-contact-description'>
              {getEmailDescription}
            </p>
            <a
              href={`mailto:${getEmailLink}`}
              className='get-section-contact-link'
            >
              <p className='get-section-contact-link '>{getEmailLink}</p>
            </a>
          </div>
          <div className='col-md-4'>
            <GetLocation className='get-section-contact-icon' />
            <p className='get-section-contact-title'>{getLocation}</p>
            <p className='get-section-contact-description'>
              {getLocationDescription}
            </p>
            {/* <div className='get-section-contact-link-location'> */}
            {/* <a href="https://www.google.com/maps/place/Salt+Lake+City,+UT,+USA/@40.7766079,-111.920485,11z/data=!3m1!4b1!4m5!3m4!1s0x87523d9488d131ed:0x5b53b7a0484d31ca!8m2!3d40.7607793!4d-111.8910474" target="_blank" rel="noreferrer" className="get-section-contact-link">{getLocationLink}</a> */}
            {/* </div> */}
            <a
              href='https://www.google.com/maps/place/Kompleksi+Kika+2,+Tirana,+Albania/@41.3169083,19.8034867,17z/data=!3m1!4b1!4m5!3m4!1s0x1350305594c80677:0xfd797a544283d430!8m2!3d41.3169043!4d19.8056754'
              target='_blank'
              rel='noreferrer'
              className='get-section-contact-link'
            >
              {getLocationLinkLocation}
            </a>
          </div>
          <div className='col-md-4'>
            <GetPhone className='get-section-contact-icon' />
            <p className='get-section-contact-title'>{getPhone}</p>
            <p className='get-section-contact-description'>
              {getPhoneDescription}
            </p>
            <a
              href={`tel:${getPhoneLink}`}
              className='get-section-contact-link'
            >
              <p className='get-section-contact-link '>{getPhoneLink}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetComponent;
